import 'bootstrap-modal';
import 'bootstrap-collapse';
import 'bootstrap-transition';

jQuery(document).ready($ => {

    const $screen = $('html,body');

    ($submissionMessages => {

        $submissionMessages.each((i, msg) => {
            let $msg = $(msg),
                $form = $msg.closest('form');

            $msg.detach();

            $form.on('submit', event => {

                $.ajax({
                    url: $form.attr('action'),
                    method: $form.attr('method') || 'POST',
                    data: $form.serialize()
                }).done(() => {
                    $form.html($msg.html());
                });

                event.preventDefault();
            });
        });
    })($('[data-on-submit]'));

    ($anchors => {

        $anchors.each((i, anchor) => {
            let $a = $(anchor);

            $a.on('click', event => {
                let width = 550,
                    height = 440,
                    top = (screen.height / 10),
                    left = (screen.width / 2) - (width / 2);

                window.open($a.attr('href'), 'sharing',
                    `height=${height},width=${width},top=${top},left=${left},status=no,toolbar=no,menubar=no,location=no`);

                event.preventDefault();
            });
        });
    })($('[data-popup]'));

    ($anchors => {

        $anchors.each((i, anchor) => {
            let $a = $(anchor),
                hash = $a.attr('href'),
                $target = (hash !== '#') ? $(hash) : $screen;

            $a.on('click', event => {

                $('#main-navbar').collapse('hide');

                if ($target.length) {

                    history.pushState(null, null, hash !== '#' ? hash : location.pathname);

                    $screen.animate({
                        scrollTop: $target.offset().top - 75
                    }, 444);
                }

                event.preventDefault();
            });
        });
    })($('a[href^="#"]'));
});
